import { ReactNode } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';

import { GetAccountResponse } from 'apps-common/graphql/getAccount';
import { BillingPeriod, PaymentMethodState, SubscriptionState } from 'apps-common/types';
import { t } from 'translations/src/LocaleContext';
import { LinkTextColor } from 'ui/components/LinkSection';

import { RowValue } from '../../components/SectionCard/PreviewRow';
import { routes } from '../../routes';
import {
  getPaymentMethodStatus,
  isB2BMember,
  isDunningSubscription,
  isExpiredSubscription,
  isLifetimeMember,
  isPrepaidMember,
  isTrialSubscription,
} from '../../utils/member';
import { getCurrentPaymentMethod } from '../../utils/paymentMethod';

const getMembershipPaymentInfo = (account: GetAccountResponse['account']) => {
  if (!account.currentSubscription?.recurringFee) return null;
  const { currentSubscription, dunningLevel } = account;
  const { billingPeriod, amount, currency } = currentSubscription.recurringFee!;
  let paymentInfo: ReactNode;

  const price = <FormattedNumber value={amount} style="currency" currency={currency} />;
  const date = currentSubscription?.nextPaymentDate ? (
    <FormattedDate value={currentSubscription.nextPaymentDate} format="default" />
  ) : (
    ''
  );
  paymentInfo = t('membership_hub_membership_plan_next_payment', {
    price,
    date,
  });

  const currentPlanType =
    billingPeriod === BillingPeriod.Months
      ? t('membership_hub_membership_plan_type_monthly')
      : t('membership_hub_membership_plan_type_annual');

  const divider = ' | ';

  if (isDunningSubscription(dunningLevel)) {
    paymentInfo = t('membership_hub_update_payment_info');
  }

  return { currentPlanType, paymentInfo, divider };
};

const daysUntil = (date?: string) => {
  if (!date) return null;
  const today = new Date();
  const targetDate = new Date(date);
  today.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  const diffInMs = targetDate.getTime() - today.getTime();
  const differenceInDays = diffInMs / (1000 * 60 * 60 * 24);

  return Math.floor(differenceInDays);
};

const handlePendingCancellation = (date: string | undefined, rowValue: RowValue[]) => {
  const daysLeft = daysUntil(date);
  if (daysLeft && daysLeft < 32) {
    rowValue.push({ value: ' | ' });
    if (daysLeft === 1) {
      rowValue.push({
        value: t('membership_hub_one_day_left'),
        color: 'lightRed',
      });
    } else {
      rowValue.push({
        value: t('membership_hub_days_left', { days: daysLeft }),
        color: 'lightRed',
      });
    }
  }

  return rowValue;
};

// b2c subscription membership plan which can have prepaid also
const handleMemberStateMembershipPlan = (account: GetAccountResponse['account']) => {
  const currentSubscription = account.currentSubscription;
  const prepaidPeriods = account.currentSubscription?.prepaidPeriods;
  const pendingCancellation = account.currentSubscription?.pendingCancellation;
  const compensationMonthsInfo =
    account.currentSubscription?.pendingCompMonths &&
    t('membership_hub_credit_months_remaining', { months: account.currentSubscription?.pendingCompMonths });

  if (isPrepaidMember(currentSubscription, 'current')) {
    const currentPrepaidPeriod = prepaidPeriods?.current;

    const from = currentPrepaidPeriod?.start ? (
      <FormattedDate value={currentPrepaidPeriod.start} format="default" />
    ) : (
      ''
    );
    const to = currentPrepaidPeriod?.end ? <FormattedDate value={currentPrepaidPeriod.end} format="default" /> : '';
    const rowValue: RowValue[] = [
      {
        value: (
          <>
            {from} - {to}
          </>
        ),
      },
    ];
    if (pendingCancellation) {
      handlePendingCancellation(currentPrepaidPeriod?.end, rowValue);
    }

    return {
      label: t('membership_hub_prepaid'),
      planDetails: [{ value: rowValue }],
    };
  } else {
    if (pendingCancellation) {
      const from = currentSubscription?.startDate ? (
        <FormattedDate value={currentSubscription.startDate} format="default" />
      ) : (
        ''
      );
      const to = currentSubscription?.endDate ? (
        <FormattedDate value={currentSubscription.endDate} format="default" />
      ) : (
        ''
      );
      const rowValue: RowValue[] = [
        {
          value: (
            <>
              {from} - {to}
            </>
          ),
        },
      ];
      handlePendingCancellation(currentSubscription?.endDate, rowValue);

      return {
        label: t('membership_hub_membership_plan'),
        planDetails: [{ value: rowValue }],
      };
    }

    const membershipInfo = getMembershipPaymentInfo(account);
    if (!membershipInfo) return null;

    const planDetailRows = [];
    const paymentInfoRow: RowValue[] = [
      { value: membershipInfo.currentPlanType },
      { value: membershipInfo.divider },
      {
        value: membershipInfo.paymentInfo,
        color: 'lightGreen',
      },
    ];
    planDetailRows.push({ value: paymentInfoRow });

    if (compensationMonthsInfo) {
      const compMonthsRow = [{ value: compensationMonthsInfo }];
      planDetailRows.push({ value: compMonthsRow });
    }

    const membershipPlan = {
      label: t('membership_hub_membership_plan'),
      planDetails: planDetailRows,
    };

    return membershipPlan;
  }
};

export const getUpcomingPlans = (account: GetAccountResponse['account']) => {
  if (!account.currentSubscription || account.currentSubscription.pendingCancellation) {
    return [];
  }

  /*
   - if current plan is a monthly / annual membership then the future plan can be only prepaid or nothing (unless we add a changed plan subscription as an upcoming plan)
   - if current plan is prepaid membership then the future plan can only be monthly / annual
   - if current plan is trial then the future plan can be prepaid, or monthly / annual
   */
  const { currentSubscription } = account;
  const prepaidPeriods = currentSubscription?.prepaidPeriods;
  const prepaidPlan = prepaidPeriods?.future;
  const upcomingPlans = [];
  const compensationMonths = currentSubscription?.pendingCompMonths;

  if (isPrepaidMember(currentSubscription, 'future')) {
    const from = prepaidPlan?.start ? <FormattedDate value={prepaidPlan.start} format="default" /> : '';
    const to = prepaidPlan?.end ? <FormattedDate value={prepaidPlan.end} format="default" /> : '';
    upcomingPlans.push({
      label: t('membership_hub_prepaid'),
      planDetails: [
        {
          value: (
            <>
              {from} - {to}
            </>
          ),
        },
      ],
    });
  }

  // only prepaid or trial subscription can have upcoming membership plan. current membership plan can't have upcoming membership plan unless we add a changed plan subscription as an upcoming plan
  const isActivePrepaidMember = isPrepaidMember(currentSubscription, 'current');
  if (isActivePrepaidMember || isTrialSubscription(currentSubscription?.subscriptionState)) {
    const membershipInfo = getMembershipPaymentInfo(account);
    const compensationMonthsInfo = t('membership_hub_credit_months_remaining', { months: compensationMonths });
    const planDetailRows: RowValue[] = [];
    const paymentRowValue: RowValue[] = [];
    const compensationRowValue: RowValue[] = [];

    if (membershipInfo) {
      paymentRowValue.push(
        { value: membershipInfo.currentPlanType },
        { value: membershipInfo.divider },
        {
          value: membershipInfo.paymentInfo,
          color: 'lightGreen',
        },
      );
      planDetailRows.push({ value: paymentRowValue });
    }

    if (compensationMonths) {
      compensationRowValue.push({ value: compensationMonthsInfo });
      planDetailRows.push({ value: compensationRowValue });
    }

    upcomingPlans.push({
      label: t('membership_hub_membership_plan'),
      planDetails: planDetailRows,
    });
  }

  return upcomingPlans;
};

export const getCurrentPlan = (
  accountData: GetAccountResponse,
): { label: ReactNode; planDetails?: RowValue[] } | null | undefined => {
  const {
    account: { membershipType, currentSubscription },
    membershipOffering: { prepaidMonths, eligibleForPartnership },
  } = accountData;
  if (!currentSubscription) return null;
  const { subscriptionState, pendingCancellation, trialExtensionLengthInMonths, endDate } = currentSubscription;

  // all EXPIRED b2c/b2b user case (not pending cancellation - handled as MEMBER state)
  if (isExpiredSubscription(subscriptionState)) {
    if (prepaidMonths > 0) {
      return {
        label: t('membership_hub_prepaid_pending_label'),
        planDetails: [
          {
            value: t('membership_pending_prepaid_months', {
              months: prepaidMonths,
            }),
          },
          {
            value: t('membership_hub_prepaid_pending_description'),
            color: 'lightRed',
          },
        ],
      };
    }

    return {
      label: t('membership_hub_expired_membership'),
      planDetails: [
        {
          value: t('membership_hub_inactive_description_get_membership_benefits'),
          color: 'lightRed',
        },
      ],
    };
  }

  const isB2B = isB2BMember(membershipType);
  if (isB2B) {
    let label;
    let detailLabel;

    if (eligibleForPartnership) {
      const { b2bPartnerName } = accountData.account;
      label = t('membership_hub_b2b_partner_plan_name', { partnerName: b2bPartnerName });
      detailLabel = t('membership_hub_b2b_partner_paid_by', { partnerName: b2bPartnerName });

      if (pendingCancellation) {
        detailLabel = t('membership_hub_essense_plan_detail_description_pending_cancel', {
          expired_date: endDate ? <FormattedDate value={endDate} format="default" /> : '',
        });
      }
    } else {
      const from = currentSubscription.startDate ? (
        <FormattedDate value={currentSubscription.startDate} format="default" />
      ) : (
        ''
      );
      const to = currentSubscription.endDate ? (
        <FormattedDate value={currentSubscription.endDate} format="default" />
      ) : (
        ''
      );
      label = t('membership_hub_b2b_plan_description');
      detailLabel = (
        <>
          {from} - {to}
        </>
      );
    }
    return {
      label,
      planDetails: [
        {
          value: detailLabel,
        },
      ],
    };
  }

  switch (subscriptionState) {
    case SubscriptionState.LIFETIME:
      return {
        label: t('membership_hub_lifetime_membership'),
      };
    case SubscriptionState.TRIAL: {
      const from = currentSubscription.startDate ? (
        <FormattedDate value={currentSubscription.startDate} format="default" />
      ) : (
        ''
      );
      const to = currentSubscription.endDate ? (
        <FormattedDate value={currentSubscription.endDate} format="default" />
      ) : (
        ''
      );
      const rowList: RowValue[] = [];
      const firstRowValue: RowValue[] = [
        {
          value: (
            <>
              {from} - {to}
            </>
          ),
        },
      ];
      if (pendingCancellation) {
        handlePendingCancellation(currentSubscription?.endDate, firstRowValue);
      }

      rowList.push({ value: firstRowValue });

      if (trialExtensionLengthInMonths) {
        rowList.push({
          value: t('membership_hub_trial_extended', { months: trialExtensionLengthInMonths }),
        });
      }
      return {
        label: t('membership_hub_x_month_trial'),
        planDetails: rowList,
      };
    }

    case SubscriptionState.MEMBER:
      return handleMemberStateMembershipPlan(accountData.account);
  }
};

// bannerErrorState color for renew
interface CTARowProps {
  cta: {
    label: string;
    color?: LinkTextColor;
    href?: string;
  };
}
export const getCTAInfo = (
  account: GetAccountResponse['account'],
  isExistingAddressValid: boolean,
  eligibleForPartnership: boolean,
): CTARowProps | null | undefined => {
  const { currentSubscription, dunningLevel, paymentMethods, membershipType } = account;
  const activePaymentMethod = getCurrentPaymentMethod(paymentMethods);
  const paymentMethodStatus = getPaymentMethodStatus(activePaymentMethod);
  const isB2B = isB2BMember(membershipType);
  const isB2BActiveMember = isB2B && currentSubscription?.subscriptionState === SubscriptionState.MEMBER;
  // ACTIVE b2b or lifetime or Eligible members don't need CTA to change/renew plan
  if (!currentSubscription || isB2BActiveMember || isLifetimeMember(membershipType) || eligibleForPartnership)
    return null;

  const { subscriptionState, recurringFee, pendingCancellation } = currentSubscription;

  // cta to renew membership (For b2b customers, only expired b2b can renew)
  if ((pendingCancellation && !isB2B) || isExpiredSubscription(subscriptionState)) {
    return {
      cta: {
        label: 'membership_hub_renew_membership',
        href: routes.reviewPaymentMethodForRenew,
        color: 'bannerErrorState',
      },
    };
  }

  // update payment method cta
  if ((dunningLevel ?? !currentSubscription.recurringFee) || paymentMethodStatus !== PaymentMethodState.ACTIVE) {
    return {
      cta: {
        label: 'membership_hub_cta_update_payment_method',
        href: isExistingAddressValid ? routes.SelectAddressUsage : routes.addressForm,
      },
    };
  }

  // cta to update rate plan
  switch (subscriptionState) {
    case SubscriptionState.TRIAL:
    case SubscriptionState.MEMBER:
      return {
        cta: {
          label:
            recurringFee?.billingPeriod === BillingPeriod.Years
              ? 'membership_hub_update_membership_plan_link'
              : 'membership_hub_switch_to_annual_plan',
          href: routes.updatePlan,
        },
      };
  }
};
